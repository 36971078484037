import React from 'react';

import { Layout, Row, Col } from 'antd';

const { Footer } = Layout;

export default class CustomFooter extends React.Component {

  render() {

    const footerStyle = { backgroundColor: "#2c3e50", paddingLeft: "40px", paddingRight: "40px" };

    const titleStyle = { color: "#FFFFFF"};

    const listStyle = { color: "#FFFFFF", listStyleType: "none", padding: "5px 0 5px 0" };

    const linkStyle = { color: "#FFFFFF" };

    return(
      <Footer style={footerStyle}>
        <Row>
          <Col span={12}>
            <h3 style={titleStyle}>Verktøy</h3>
            <ul style={listStyle}>
              <li><a href="https://roadmanager.app" style={linkStyle}>RoadManager</a></li>
              <li><a href="https://em.roadworks.io" style={linkStyle}>EquipmentManager</a></li>
              <li><a href="https://ue.roadworks.no" style={linkStyle}>UE Portal</a></li>
              <li><a href="https://dashboard.roadworks.io" style={linkStyle}>Dashbord</a></li>
            </ul>
          </Col>
          <Col span={12}>
            <h3 style={titleStyle}>Hjelp og støtte</h3>
            <ul style={listStyle}>
              <li>Epost: roadmanager@traftec.no</li>
              <li>Tlf: 90625566</li>
              <li><a href="https://roadworks-no.facebook.com/groups/197500470657817/" style={linkStyle}>Workplace support</a></li>
            </ul>
          </Col>
        </Row>
      </Footer>
    );
  }
}