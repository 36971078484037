import React from 'react';
import './App.css';
import Auth from './utils/Auth'

import { AuthenticatedRoute, AuthorizedRoute } from './utils/routing';
import { Route, Switch } from 'react-router-dom';
import Login from './components/login/Login';

import Navbar from './components/Navbar';

import { Layout } from 'antd';
import VehiclesOverview from "./components/VehiclesOverview";
import VehiclesEditor from "./components/VehiclesEditor";
import Dashboard from "./components/Dashboard";
import EquipmentOverview from "./components/EquipmentOverview";
import EquipmentEditor from "./components/EquipmentEditor";
import CustomHeader from "./components/CustomHeader";
import NoAccess from "./components/utils/NoAccess";
import VehiclesDatabase from "./components/VehiclesDatabase";
import EquipmentDatabase from "./components/EquipmentDatabase";
import CustomFooter from "./components/CustomFooter";

const { Content } = Layout;

const auth = new Auth();

class App extends React.Component {
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Route exact path="/login" component={Login} />
        <Route exact path="/noaccess" component={NoAccess} />

        <Route exact path="/Auth" render={(props) => {
          auth.handleAuthentication(props);
          return <div>Henter data...</div>
        }} />

        <AuthenticatedRoute component={ContentContainer} />
      </div>
    );
  }
}

class ContentContainer extends React.Component {

  render() {

    return (
      <div className="container">
        <Layout style={{ minHeight: "100vh" }}>

          <CustomHeader />
          <Navbar />

          <Content style={{ margin: "20px", minHeight: "100vh" }}>
            <Switch>
              <AuthorizedRoute permission='core:w' exact path='/' component={Dashboard} />

              <AuthorizedRoute permission='core:w' exact path='/vehicles' component={VehiclesOverview} />
              <AuthorizedRoute permission='core:w' exact path='/vehicles/edit/' component={VehiclesEditor} />
              <AuthorizedRoute permission='core:w' exact path='/vehicles/edit/:id' component={VehiclesEditor} />

              <AuthorizedRoute permission='core:w' exact path='/equipment' component={EquipmentOverview} />
              <AuthorizedRoute permission='core:w' exact path='/equipment/edit/' component={EquipmentEditor} />
              <AuthorizedRoute permission='core:w' exact path='/equipment/edit/:id' component={EquipmentEditor} />

              <AuthorizedRoute permission='core:w' exact path='/vehiclesDatabase' component={VehiclesDatabase} />
              <AuthorizedRoute permission='core:w' exact path='/equipmentDatabase' component={EquipmentDatabase} />
            </Switch>
          </Content>

          <CustomFooter />
        </Layout>
      </div>
    );
  }
}

export default App;
