import { client } from './http';

/**
 * Provides access to Suppliers
 * The Supplier schema is defined in the API-docs
 */
export default class Suppliers {


    /**
     * Retrieves a list of all Suppliers
     * @returns {Promise} Promise object that returns a list of elements
     */
    static get() {
        return new Promise((resolve, reject) => {

        const url = '/suppliers';
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    };

}