import { client } from './http';

/**
 * Provides access to equipment
 * The Equipment schema is defined in the API-docs
 */
export default class Equipment {


    /**
     * Retrieves a single equipment based on the given ID
     * @param {string} equipmentId A valid vehicle ID
     * @returns {Promise} Promise object that returns a single element
     */
    static get(equipmentId) {
        return new Promise((resolve, reject) => {
            const url = '/equipment/' + equipmentId;
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    }


    /**
     * Retrieves a list of Equipment based on the provided query
     * Query values set to 'null' is ignored
     * @param {string} companyId [REQUIRED] A company ID 
     * @param {string[]} departments [OPTIONAL] An array of department IDs
     * @param {string[]} projects [OPTIONAL] An array of project IDs
     * @returns {Promise} Promise object that returns a list of elements
     */
    static getAll(companyId, departments, projects) {
        const url = '/equipment';
        const params = { companyId: companyId };
        if(departments) params.departments = JSON.stringify(departments);
        if(projects) params.projects = JSON.stringify(projects);

        return new Promise((resolve, reject) => {
            client().get(url, { params: params }).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    };


    /**
     * Creates a new Equipment
     * The document must contain all values described in the API-docs
     * Additional values will be stripped
     * 
     * @param {object} document The Equipment to create
     * @returns {Promise} Promise object that returns the newly created elements
     */
    static create(document) {
        return new Promise((resolve, reject) => {
            const url = '/equipment';
            client().post(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }


    /**
     * Updates an existing Equipment
     * The document must contain all values described in the API-docs
     * 
     * @param {string} documentId 
     * @param {object} document 
     * @returns {Promise} Promise object that returns the newly updated elements.
     */
    static update(documentId, document) {
        return new Promise((resolve, reject) => {
        const url = '/equipment/' + documentId;
            client().put(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    /**
     * Deletes the document with the given ID
     *
     * @param {string} documentId
     * @returns {Promise} Promise object that returns the delted element
     */
    static remove(documentId) {
        return new Promise((resolve, reject) => {
            const url = '/equipment/' + documentId;
            client().delete(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

}