import { client } from './http';

/**
 * Provides access to projects
 * The Project schema is defined in the API-docs
 */
export default class Projects {


    /**
     * Retrieves a single Project based on the given ID
     * @param {string} projectId A valid project ID
     * @returns {Promise} Promise object that returns a single element
     */
    static get(projectId) {
        return new Promise((resolve, reject) => {
            const url = '/projects/' + projectId;
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    }


    /**
     * Retrieves a list of all projects based on the provided query
     * Query values set to 'null' is ignored
     * 
     * @param {string} companyId [REQUIRED] A company ID 
     * @returns {Promise} Promise object that returns a list of elements
     */
    static getAll(companyId) {
        let url = '/projects';

        const params = {};
        // if(companyId) params.companyId = companyId;

        console.log("Params", params);
        return new Promise((resolve, reject) => {
            client().get(url, { params: params }).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(err);
            });
        });
    };


    /**
     * Retrieves a list of projects matching the given list of projectIds
     * 
     * @param {String[]} projectIds A list of projectIds
     * @returns {Promise} Promise object that returns a list of elements
     */
    static getByIds(projectIds){
        return new Promise(async(resolve, reject) => {
            try{
                const results = await this.getAll(null);
                return resolve(results.filter(e => projectIds.includes(e._id)));
            }catch(error) {
                return reject(error);
            }
        });
    }


    /**
     * Retrieves a list of projects that belongs to any of the given departmentIds
     * 
     * @param {String[]} departmentIds A list of departmentIds
     * @returns {Promise} Promise object that returns a list of elements
     */
    static getByDepartment(departmentIds){
        return new Promise(async(resolve, reject) => {
            try{
                const results = await this.getAll(null);
                return resolve(results.filter(e => departmentIds.includes(e.departmentId)));
            }catch(error) {
                return reject(error);
            }
        });
    }



    /**
     * Creates a new Project
     * The document must contain all values described in the API-docs
     * Additional values will be stripped
     * 
     * @param {object} document The Project to create
     * @returns {Promise} Promise object that returns the newly created elements
     */
    static create(document) {
        return new Promise((resolve, reject) => {
            const url = '/projects';
            client().post(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(err);
            });
        });
    }


    /**
     * Updates an existing Project
     * The document must contain all values described in the API-docs
     * 
     * @param {string} documentId 
     * @param {object} document 
     * @returns {Promise} Promise object that returns the newly updated elements.
     */
    static update(documentId, document) {
        return new Promise((resolve, reject) => {
        const url = '/projects/' + documentId;
            client().put(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(err);
            });
        });
    }


    /**
     * Deletes the Project with the given ID
     *
     * @param {string} documentId
     * @returns {Promise} Promise object that returns the delted element
     */
    static remove(documentId) {
        return new Promise((resolve, reject) => {
            const url = '/projects/' + documentId;
            client().delete(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(err);
            });
        });
    }
    
}