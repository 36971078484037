import { client } from './http';

/**
 * Provides access to milestones
 * The Milestone schema is defined in the API-docs
 */
export default class Milestones {


    /**
     * Retrieves a single milestone based on the given ID
     * @param {string} milestoneId A valid milestone ID
     * @returns {Promise} Promise object that returns a single element
     */
    static get(milestoneId) {
        return new Promise((resolve, reject) => {
            const url = '/milestones/' + milestoneId;
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    };


    /**
     * Retrieves a list of Milestones belonging to the object with the given parent ID.
     * The Parent ID can belong to any object tied to a milestone
     * @param {string} parentId A valid parent ID
     * @returns {Promise} Promise object that returns a list of elements
     */
    static getByParentId(parentId) {
        return new Promise((resolve, reject) => {
        const url = '/milestones?parentId=' + parentId;
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    };


    /**
     * Creates a new Milestone
     * The document must contain all values described in the API-docs
     * Additional values will be stripped
     * @param {object} document 
     * @returns {Promise} Promise object that returns the newly created elements
     */
    static create(document) {
        console.log("Creating!");
        return new Promise((resolve, reject) => {
        const url = '/milestones';
            client().post(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    };


    /**
     * Updates an existing Milestone
     * The document must contain all values described in the API-docs
     * Additional values will be stripped
     * @param {string} documentId 
     * @param {object} document 
     * @returns {Promise} Promise object that returns the newly created elements
     */
    static update(documentId, document) {
        return new Promise((resolve, reject) => {
        const url = '/milestones/' + documentId;
            client().put(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    };

}