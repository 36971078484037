import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Table, Card, Button } from 'antd';
import MilestoneModal from "./modals/MilestoneModal";

import { getMilestoneStateTag } from '../utils/tags';

import { MilestoneTypes } from '../utils/em-client';

export default class MilestonesTable extends React.Component {

  constructor(props) {
    super(props);

    this.onNewMilestone = this.onNewMilestone.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onDetailsClicked = this.onDetailsClicked.bind(this);

    this.state = { isLoading: true, milestoneTypes: [], isModalVisible: false, selectedObject: null };
  }

  componentDidMount() {

    this.setState({ isLoading: true });

    MilestoneTypes.get().then(result => {
      this.setState({ isLoading: false, milestoneTypes: result });
    }).catch(error => {
      this.setState({ isLoading: false, milestoneTypes: [] });
    });
  }

  onModalClose() {
    this.props.onUpdate();
    this.setState({ isModalVisible: false, selectedObject: null });
  }

  onDetailsClicked(element) {
    this.setState({ selectedObject: element, isModalVisible: true });
  }

  onNewMilestone() {
    this.setState({ isModalVisible: true });
  }

  renderPlaceholder() {
    return (<div>For å opprette en oppgave må du først lagre kjøretøyet/utstyret.</div>)
  }

  renderTable() {

    const data = this.props.milestones.map(e => {
      const milestoneType = this.state.milestoneTypes.find(t => t._id === e.milestoneTypeId);
      const milestoneTypeTitle = milestoneType ? milestoneType.title : "";
      const deadlineText = moment.unix(e.deadline).format("DD.MM.YYYY");
      const completedText = e.completed ? moment.unix(e.completed).format("DD.MM.YYYY") : "";
      return { milestoneType: milestoneTypeTitle, deadline: deadlineText, completed: completedText, state: e, actions: e };
    });

    const columns = [
      {
        title: 'Type',
        dataIndex: 'milestoneType',
        key: 'milestoneType',
      },
      {
        title: 'Frist',
        dataIndex: 'deadline',
        key: 'deadline',
      },
      {
        title: 'Utført',
        dataIndex: 'completed',
        key: 'completed',
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        render: (v, e) => { return getMilestoneStateTag(v); }
      },
      {
        title: 'Handlinger',
        dataIndex: 'actions',
        key: 'actions',
        render: (e, v) => { return (<Button type="default" size="small" onClick={() => this.onDetailsClicked(e)}>Detaljer</Button>) }
      },
    ];

    const style = { backgroundColor: "#FFFFFF" };

    return (
      <Table columns={columns} dataSource={data} style={style} />
    );
  }

  render() {

    if (this.state.isLoading)
      return <Card loading={true} />

    if (this.props.isDisabled)
      return this.renderPlaceholder();

    const buttonStyle = { marginBottom: "20px" };

    return (
      <div>
        <Button type="primary" style={buttonStyle} onClick={this.onNewMilestone}>Opprett ny oppgave</Button>
        {this.renderTable()}

        <MilestoneModal parentObject={this.props.parentObject} object={this.state.selectedObject} isVisible={this.state.isModalVisible} onClose={this.onModalClose} milestoneTypes={this.state.milestoneTypes} />
      </div>
    );
  }
}

MilestonesTable.propTypes = {
  parentObject: PropTypes.object.isRequired,
  milestones: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};