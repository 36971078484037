import auth0 from 'auth0-js';
import { jwtDecode } from "jwt-decode";
import Axios from 'axios';

const redirectUri = process.env.REACT_APP_REDIRECT_URI;
if (!redirectUri) console.warn('process.env.REACT_APP_REDIRECT_URI, is: ', redirectUri);

const auth = new auth0.WebAuth({
  domain: 'roadworks.eu.auth0.com',
  clientID: 'ozXCTD6lMUpkHFWr7vRi0ycFWkQt01Mc',
  redirectUri: redirectUri || window.origin + '/Auth',
  audience: 'roadworks-api',
  responseType: 'token id_token',
  scope: 'openid profile user_metadata nickname'
});

export default class Auth {

  login() {
    auth.authorize();
  }

  handleAuthentication(nextState) {
    auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const access = jwtDecode(authResult.accessToken);
        console.log("Access: ", access);
        localStorage.setItem('em_permissions', access.permissions);

        this.setSession(authResult);

        this.getProfile().then(profile => {
          this.setProfile(profile);
          nextState.history.push('/');
        }).catch(error => {
          console.log(error);
        });
      } else if (err) {
        console.log("Error: ", err);
        nextState.history.replace('/login');
        console.log(err);
      }
    });
  }


  getProfile() {
    return new Promise(async (resolve, reject) => {
      const url = process.env.REACT_APP_EM_API_URL + "/core/profile";
      const headers = { "Authorization": "Bearer " + Auth.getAccessToken() };

      Axios.get(url, { headers: headers }).then(result => {
        console.log(result);
        const data = result.data;

        // const authorization = data.app_metadata.authorization ? data.app_metadata.authorization : { roles: [], permissions: [], groups: [] };

        const profile = {
          email: data.email,
          userId: data._id,
          nickname: data.nickname,
          picture: data.picture,
          companyId: data.companyId ? data.companyId : "",
          projects: data.projects ? data.projects : [],
          departments: data.departments ? data.departments : [],
          // authorization: {
          //   roles: authorization.roles,
          //   permissions: authorization.permissions,
          //   groups: authorization.groups,
          // }
        };
        return resolve(profile);
      }).catch(error => {
        console.error(error);
        return reject(error);
      });

    });
  }

  setSession(authResult) {
    console.log("setting session");
    console.log(authResult);
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  setProfile(profile) {
    console.log("Setting user profile");
    console.log(profile);
    localStorage.setItem('userProfile', JSON.stringify(profile));
  }

  static getProp(prop) {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return "";
    return data[prop] ? data[prop] : "";
  };

  static getUserId() {
    const userProfile = localStorage.getItem('userProfile');
    if (!userProfile) return null;
    const profile = JSON.parse(userProfile);
    return profile["userId"];
  }

  static getCompany() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return "";
    return data.companyId ? data.companyId : "";
  };

  static getCompanyId() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return "";
    return data.companyId ? data.companyId : "";
  };

  static getPictureUrl() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return "";
    return data.picture ? data.picture : null;
  };

  static getProjects() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return [];
    return data.projects ? data.projects : [];
  };

  static getDepartments() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return [];
    return data.departments ? data.departments : [];
  };

  static getAccesses() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return null;
    let accesses = {};
    accesses.groups = {};
    accesses.roles = {};
    accesses.permissions = {};

    if (data && data.authorization) {
      if (data.authorization.groups) {
        let groups = data.authorization.groups;
        for (let i = 0; i < groups.length; i++) {
          accesses.groups[groups[i]] = true;
        }
      }
      if (data.authorization.roles) {
        let roles = data.authorization.roles;
        for (let i = 0; i < roles.length; i++) {
          accesses.roles[roles[i]] = true;
        }
      }
      if (data.authorization.permissions) {
        let permissions = data.authorization.permissions;
        for (let i = 0; i < permissions.length; i++) {
          accesses.permissions[permissions[i]] = true;
        }
      }
    }

    return accesses;
  }

  static getGroups() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return [];

    return data.authorization ? data.authorization.groups : [];
  };

  static getRoles() {
    const data = JSON.parse(localStorage.getItem('userProfile'));
    if (!data) return [];

    return data.authorization ? data.authorization.roles : [];
  };

  static getPermissions() {
    const data = localStorage.getItem('em_permissions');
    if (!data) return [];

    return data;
  };

  static hasGroups(allowed) {
    const groups = this.getGroups();
    if (Array.isArray(allowed)) {
      return groups.some((e) => {
        return allowed.includes(e);
      });
    }
    else if (typeof allowed === 'string' || allowed instanceof String) {
      if (groups.indexOf(allowed) !== -1) return true;
    }
    return false;
  }

  static hasPermissions(allowed) {
    const permissions = this.getPermissions();

    console.log("Permissions: ", permissions);
    console.log("Allowed: ", allowed);

    if (Array.isArray(allowed)) {
      return permissions.some((e) => {
        return allowed.includes(e);
      });
    }
    else if (typeof allowed === 'string' || allowed instanceof String) {
      if (permissions.indexOf(allowed) !== -1) return true;
    }

    console.log("Returning false");
    return false;
  }

  static logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // localStorage.clear();    Do not clear, localStoreage is used to other things as well
    // navigate to the home route
    window.location.href = "/"
  }

  static isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  static getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) return accessToken;
  }

}