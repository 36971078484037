import React from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  DatePicker,
  Divider,
} from "antd";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

export default class MilestoneForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const objectId = this.props.object ? this.props.object._id : null;
        this.props.onFormSubmit(objectId, values);
      }
    });
  };

  componentDidMount() {
    if (!this.props.object) return;

    const keys = {};

    Object.keys(this.props.object).forEach((key) => {
      switch (key) {
        case "deadline":
          keys[key] = moment.unix(this.props.object[key]);
          break;
        case "completed":
          keys[key] = moment.unix(this.props.object[key]);
          break;
        default:
          keys[key] = this.props.object[key];
          break;
      }
    });

    this.props.form.setFieldsValue(keys);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const milestoneTypes = this.props.milestoneTypes.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.title}
        </Option>
      );
    });
    const isCompletedDisabled = !this.props.object;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Type">
          {getFieldDecorator("milestoneTypeId", {
            rules: [{ required: true, message: "Vennligst velg type" }],
          })(<Select>{milestoneTypes}</Select>)}
        </FormItem>

        <FormItem {...formItemLayout} label="Kommentar">
          {getFieldDecorator("comment")(<TextArea type="text" rows={2} />)}
        </FormItem>

        <FormItem {...formItemLayout} label="Frist for utførelse">
          {getFieldDecorator("deadline", {
            rules: [
              {
                required: true,
                message: "Vennligst fyll inn frist for utførelse",
              },
            ],
          })(<DatePicker format="DD.MM.YYYY" />)}
        </FormItem>

        <Divider />

        <FormItem {...formItemLayout} label="Utført dato">
          {getFieldDecorator("completed")(
            <DatePicker disabled={isCompletedDisabled} format="DD.MM.YYYY" />
          )}
        </FormItem>

        <Divider />

        <FormItem>
          <Row>
            <Col span={4} />
            <Col span={20}>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.props.isLoading}
              >
                Lagre
              </Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    );
  }
}
