import { client } from './http';

/**
 * Provides access to MilestoneTypes
 * The MilestoneType schema is defined in the API-docs
 */
export default class MilestoneTypes {


    /**
     * Retrieves a list of all MilestoneTypes
     * @returns {Promise} Promise object that returns a list of elements
     */
    static get() {
        return new Promise((resolve, reject) => {
            const url = '/milestoneTypes';
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });

        });
    };

}