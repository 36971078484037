import { client } from './http';

export default class Users {
  static getAll(companyId) {
    let url = '/core/users';

    const params = {};
    if (companyId) params.companyId = companyId;

    return new Promise((resolve, reject) => {
      client().get(url, { params: params }).then(res => {
        return resolve(res.data);
      }).catch(err => {
        console.error(err);
        return reject(err);
      });
    });
  };

  static getByCompany(companyId) {
    if (!companyId) return [];

    const params = {};
    params.companyId = companyId;

    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.getAll();
        return resolve(result);
      } catch (error) {
        if (error.response) return reject(error.response);
        else if (error.request) console.log(error.request);
        else console.log('Error', error.message);
      }
    });
  }
}