import React from 'react';
import PropTypes from 'prop-types';
import Auth from '../utils/Auth';

import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';

import { getStateTag, getMilestoneTag, getStateText } from '../utils/tags';

import { sort } from "../utils/sorting";
import moment from 'moment';


export default class VehiclesTable extends React.Component {

  getUpcomingMilestoneLabel(milestone) {

    if(!milestone) return;

    const milestoneType = this.props.milestoneTypes.find(e => e._id === milestone.milestoneTypeId);
    return (<span> { milestoneType ? milestoneType.title : "Ukjent" } {moment.unix(milestone.deadline).format("DD.MM.YYYY")}</span>)
  }

  hasAccessToElement(departmentId){

    if(!departmentId) return false;

    const departments = Auth.getDepartments();

    if(departments.includes(departmentId)) return true;

    return false;
  }

  render() {

    let data = this.props.vehicles.map(e => {

      const department = this.props.departments.find(t => t._id === e.departmentId);
      const project = this.props.projects.find(t => t._id === e.projectId);
      const brand = this.props.vehicleTypes.find(t => t._id === e.brand);
      const responsibles = this.props.users.filter(t => e.responsibles.includes(t.user_id)).map(t => t.name).join(", ");


      return {
        department: department ? department.title : "",
        project: project ? project.title : "Ikke tilknyttet prosjekt",
        registrationNumber: e.registrationNumber,
        brand: brand ? brand.title : "",
        year: e.year + "",
        milestones: e,
        upcomingMilestone: e.upcomingMilestone,
        state: e.state,
        responsibles: responsibles,
        actions: e
      }
    });

    const searchWord = this.props.searchWord.toLowerCase();

    if(searchWord){
      data = data.filter(e =>
        (e.department && e.department.toLowerCase().includes(searchWord)) ||
        (e.project && e.project.toLowerCase().includes(searchWord)) ||
        (e.registrationNumber && e.registrationNumber.toLowerCase().includes(searchWord)) ||
        (e.brand && e.brand.toLowerCase().includes(searchWord)) ||
        (e.year && e.year.includes(searchWord)) ||
        (e.responsibles && e.responsibles.includes(searchWord))
      );
    }


    const departmentsFilters = [...new Set(data.map(e => e.department))].map(e => { return { text: e, value: e}});
    const projectsFilters = [...new Set(data.map(e => e.project))].map(e => { return { text: e, value: e}});
    const stateFilters = [...new Set(data.map(e => e.state))].map(e => { return { text: getStateText(e), value: e}});

    const columns = [
      {
        title: 'Avdeling',
        dataIndex: 'department',
        key: 'department',
        filters: departmentsFilters,
        onFilter: (value, record) => record.department === value,
        sorter: (a, b) => sort(a.department, b.department),
      },
      {
        title: 'Prosjekt',
        dataIndex: 'project',
        key: 'project',
        filters: projectsFilters,
        onFilter: (value, record) => record.project === value,
        sorter: (a, b) => sort(a.project, b.project),
      },
      {
        title: 'Registreringsnummer',
        dataIndex: 'registrationNumber',
        key: 'registrationNumber',
        sorter: (a, b) => sort(a.registrationNumber, b.registrationNumber),
      },
      {
        title: 'Merke',
        dataIndex: 'brand',
        key: 'brand',
        sorter: (a, b) => sort(a.brand, b.brand),
      },
      {
        title: 'Årsmodell',
        dataIndex: 'year',
        key: 'year',
        sorter: (a, b) => sort(a.year, b.year),
      },
      {
        title: 'Tilstand',
        dataIndex: 'milestones',
        key: 'milestones',
        render: (v,e) => { return getMilestoneTag(v); },
      },
      {
        title: 'Neste oppgave  ',
        dataIndex: 'upcomingMilestone',
        key: 'upcomingMilestone',
        render: (v,e) => { return this.getUpcomingMilestoneLabel(v); },
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilters,
        onFilter: (value, record) => parseInt(record.state) === parseInt(value),
        render: (v,e) => { return getStateTag(v); },
        sorter: (a, b, sortOrder) => a.state - b.state,
      },
      {
        title: 'Ansvarlig',
        dataIndex: 'responsibles',
        key: 'responsibles'
      },
      {
        title: 'Handlinger',
        dataIndex: 'actions',
        key: 'actions',
        render: (e,v) => { return (<Button size="small" disabled={!this.hasAccessToElement(e.departmentId)}><Link to={"/vehicles/edit/" + e._id}>Endre</Link></Button>)}
      },
    ];

    const style = { backgroundColor: "#FFFFFF"};

    return (
      <Table columns={columns} dataSource={data} style={style} pagination={false} loading={this.props.isLoadingData} />
    );
  }
}

VehiclesTable.propTypes = {
  vehicles: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  milestoneTypes: PropTypes.array.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  searchWord: PropTypes.string.isRequired,
};