import http from 'axios';

const apiUrl = process.env.REACT_APP_GRAPH_CORE_URL;

export const client = function () {
  let token = localStorage.getItem('access_token');
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  http.defaults.baseURL = "https://graph.core.roadworks.io";
  http.defaults.headers.post['Content-Type'] = 'application/json';
  return http;
};