import React from 'react';
import Auth from '../utils/Auth';

import { Card, Input, notification } from 'antd';

import { Vehicles, VehicleTypes, MilestoneTypes } from '../utils/em-client'
import { Departments, Projects } from '../utils/st-client';

import { Users } from "../utils/em-client";
import moment from 'moment';
import VehiclesTable from "./VehiclesTable";

const Search = Input.Search;

export default class VehiclesDatabase extends React.Component {

  constructor(props) {
    super(props);
    this.state = { elements: [], departments: [], projects: [], vehicleTypes: [], users: [], milestoneTypes: [], isLoadingData: false, searchWord: "" }
  }

  componentDidMount() {

    this.setState({ isLoadingData: true });

    this.getData().catch(error => {
      notification.error({ message: "Det oppsto en feil under innlasting av data." });
    });

  }

  getData() {

    const userCompanyId = Auth.getCompany();

    return new Promise(async (resolve, reject) => {

      try {
        const elements = await Vehicles.getAll(userCompanyId, null, null);
        const departments = await Departments.getAll();

        const milestoneTypes = await MilestoneTypes.get();

        const projects = await Projects.getAll(userCompanyId);
        const vehicleTypes = await VehicleTypes.get();
        const users = await Users.getAll();

        this.setState({
          elements: elements,
          departments: departments,
          projects: projects,
          vehicleTypes: vehicleTypes,
          milestoneTypes: milestoneTypes,
          users: users,
          isLoadingData: false
        });
        resolve(null);
      } catch (e) {
        reject(e);
      }
    });
  }

  getUpcomingMilestoneLabel(milestone) {

    if (!milestone) return;

    const milestoneType = this.state.milestoneTypes.find(e => e._id === milestone.milestoneTypeId);
    return (<span> {milestoneType ? milestoneType.title : "Ukjent"} {moment.unix(milestone.deadline).format("DD.MM.YYYY")}</span>)
  }

  renderPanel() {

    const style = { marginBottom: "20px" };

    return (
      <Card style={style}>

        <Search
          style={{ width: "500px" }}
          placeholder="Søk etter avdeling, prosjekt, registreringsnummer, osv..."
          onSearch={value => this.setState({ searchWord: value })}
        />
      </Card>
    );
  }

  render() {
    return (
      <div>
        {this.renderPanel()}

        <VehiclesTable
          vehicles={this.state.elements}
          departments={this.state.departments}
          projects={this.state.projects}
          vehicleTypes={this.state.vehicleTypes}
          users={this.state.users}
          milestoneTypes={this.state.milestoneTypes}
          isLoadingData={this.state.isLoadingData}
          searchWord={this.state.searchWord} />

      </div>
    );
  }
}