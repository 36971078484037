import { client } from './http';

/**
 * Provides access to companies
 * The Company schema is defined in the API-docs
 */
export default class Companies {


    /**
     * Retrieves a single company based on the given ID
     * @param {string} companyId A valid company ID
     * @returns {Promise} Promise object that returns a single element
     */
    static get(companyId) {
        return new Promise((resolve, reject) => {
            const url = '/identity/tenants/' + companyId;
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    }


    /**
     * Retrieves a list of all companies
     * @returns {Promise} Promise object that returns a list of elements
     */
    static getAll() {
        let url = '/identity/tenants/';
        return new Promise((resolve, reject) => {
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    };


    /**
     * Creates a new Company
     * The document must contain all values described in the API-docs
     * Additional values will be stripped
     * 
     * @param {object} document The Company to create
     * @returns {Promise} Promise object that returns the newly created elements
     */
    static create(document) {
        return new Promise((resolve, reject) => {
            const url = '/companies';
            client().post(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }


    /**
     * Updates an existing Company
     * The document must contain all values described in the API-docs
     * 
     * @param {string} documentId 
     * @param {object} document 
     * @returns {Promise} Promise object that returns the newly updated elements.
     */
    static update(documentId, document) {
        return new Promise((resolve, reject) => {
        const url = '/companies/' + documentId;
            client().put(url, document).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }


    /**
     * Deletes the Company with the given ID
     *
     * @param {string} documentId
     * @returns {Promise} Promise object that returns the delted element
     */
    static remove(documentId) {
        return new Promise((resolve, reject) => {
            const url = '/companies/' + documentId;
            client().delete(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }
    
}