import { Tag } from 'antd';
import React from 'react';

export function getStateTag(state) {
  switch (state) {
    case 1:
      return (<Tag color="cyan">{getStateText(state)}</Tag>);
    case 2:
      return (<Tag color="geekblue">{getStateText(state)}</Tag>);
    case 3:
      return (<Tag color="purple">{getStateText(state)}</Tag>);
    default:
      return null;
  }
}

export function getStateText(state) {
  switch (state) {
    case 1:
      return "I bruk";
    case 2:
      return "Ledig (Ikke i bruk)";
    case 3:
      return "Kassert";
    default:
      return "";
  }
}

export function getMilestoneTag(vehicle) {

  if(!vehicle)
    return null;

  if(vehicle.ok)
    return <Tag color="green">Ok</Tag>;

  return <Tag color="red">{vehicle.numExpiredMilestones} utløpte oppgaver</Tag>;
}


export function getMilestoneStateTag(milestoneElement) {

  if(!milestoneElement)
    return null;

  if(milestoneElement.completed)
    return <Tag color="geekblue">Fullført</Tag>;

  const timestamp = Math.round(new Date().getTime() / 1000);

  if(timestamp > milestoneElement.deadline)
    return <Tag color="red">Utløpt</Tag>;

  return <Tag color="green">Aktiv</Tag>;
}