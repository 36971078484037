import React from 'react';
import PropTypes from 'prop-types';

import { PieChart, Pie, Cell, Sector } from 'recharts';
import { Card } from 'antd';

const COLORS = ['#2ecc71', '#e74c3c'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}: ${value} stk`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Andel ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default class VehicleDistributionChart extends React.Component {

  constructor(props){
    super(props);

    this.onPieEnter = this.onPieEnter.bind(this);

    this.state = { activeIndex: 0 };
  }

  onPieEnter(data, index) {
    this.setState({ activeIndex: index });
  }

  render() {

    if(!this.props.vehicles)
      return <Card title={this.props.title} loading={true} />;

    if(this.props.vehicles.length === 0)
      return <Card title={this.props.title}>Ingen utstyr i databasen</Card>

    const dataSource = [
      {
        name: 'Kjøretøy uten mangler',
        value: this.props.vehicles.filter(e => e.ok).length
      },
      {
        name: 'Kjøretøy med mangler',
        value: this.props.vehicles.filter(e => !e.ok).length
      }
    ];

    return(
      <Card title={this.props.title} hoverable={true}>
        <PieChart width={800} height={400}>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={this.onPieEnter}
            data={dataSource}
            cx={350}
            cy={200}
            outerRadius={80}
            fill="#8884d8">
            {
              dataSource.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
            }
          </Pie>
        </PieChart>
      </Card>
    );
  }
}

VehicleDistributionChart.propTypes = {
  title: PropTypes.string.isRequired,
  vehicles: PropTypes.array.isRequired
};