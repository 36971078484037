import React from 'react';
import Auth from '../utils/Auth';

import { Card, Input } from 'antd';

import { Equipment } from '../utils/em-client'
import { Departments, Projects } from '../utils/st-client';

import { Users } from "../utils/em-client/index";
import EquipmentTable from "./EquipmentTable";
import { MilestoneTypes } from "../utils/em-client/index";
import { notification } from "antd/lib/index";

const Search = Input.Search;

export default class EquipmentDatabase extends React.Component {

  constructor(props) {
    super(props);
    this.state = { elements: [], departments: [], projects: [], users: [], milestoneTypes: [], isLoadingData: false, searchWord: "" }
  }

  componentDidMount() {

    this.setState({ isLoadingData: true });

    this.getData().catch(error => {
      notification.error({ message: "Det oppsto en feil under innlasting av data." });
    });
  }

  getData() {

    return new Promise(async (resolve, reject) => {

      const userCompanyId = Auth.getCompany();

      try {
        const elements = await Equipment.getAll(userCompanyId, null, null);
        const departments = await Departments.getAll();
        const projects = await Projects.getAll(userCompanyId);

        const milestoneTypes = await MilestoneTypes.get();
        const users = await Users.getAll();

        this.setState({
          elements: elements,
          departments: departments,
          projects: projects,
          milestoneTypes: milestoneTypes,
          users: users,
          isLoadingData: false
        });
        resolve(null);
      } catch (e) {
        reject(e);
      }
    });
  }

  renderPanel() {

    const style = { marginBottom: "20px" };

    return (
      <Card style={style}>
        <Search
          style={{ width: "500px" }}
          placeholder="Søk etter avdeling, prosjekt, merke, beskrivelse, osv..."
          onSearch={value => this.setState({ searchWord: value })}
        />
      </Card>
    );
  }

  render() {
    return (
      <div>
        {this.renderPanel()}
        <EquipmentTable
          equipment={this.state.elements}
          departments={this.state.departments}
          projects={this.state.projects}
          vehicleTypes={this.state.vehicleTypes}
          users={this.state.users}
          milestoneTypes={this.state.milestoneTypes}
          isLoadingData={this.state.isLoadingData}
          searchWord={this.state.searchWord} />
      </div>
    );
  }
}