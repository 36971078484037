import React from 'react';
import PropTypes from 'prop-types';
import { Milestones } from '../../utils/em-client';

import { Modal, Form } from 'antd';
import MilestoneForm from "../forms/MilestoneForm";


export default class MilestoneModal extends React.Component {

  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = { isLoading: false };
  }

  onCancel() {
    this.props.onClose();
  }

  onSubmit(objectId, value) {
    this.setState({ isLoading: true });

    value.parentId = this.props.parentObject._id;
    value.deadline = value.deadline.unix();

    if (value.completed)
      value.completed = value.completed.unix();

    if (!objectId) {


      Milestones.create(value).then(result => {
        this.onCancel();
        this.setState({ isLoading: false });
      }).catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });

    } else {

      Milestones.update(objectId, value).then(result => {
        this.onCancel();
        this.setState({ isLoading: false });
      }).catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });

    }

  }

  render() {
    const modalProps = {
      title: "Ny oppgave",
      visible: this.props.isVisible,
      onCancel: this.onCancel,
      width: 800,
      footer: null
    };

    const props = {
      onFormSubmit: this.onSubmit,
      milestoneTypes: this.props.milestoneTypes,
      object: this.props.object,
      isLoading: this.state.isLoading,
    };
    const WrappedMilestoneForm = Form.create()(MilestoneForm);

    return (
      <Modal {...modalProps}>

        <WrappedMilestoneForm {...props} />

      </Modal>
    );
  }
}

MilestoneModal.propTypes = {
  object: PropTypes.object,
  parentObject: PropTypes.object.isRequired,
  milestoneTypes: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};