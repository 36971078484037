import React from 'react';

import { Form, Row, Col, Card, Button, Icon, Popconfirm, notification } from 'antd';

import { Suppliers, Equipment, InventoryStates, Milestones } from '../utils/em-client';
import { Companies, Departments, Projects } from '../utils/st-client';

import { Users } from '../utils/em-client';

import MilestonesTable from "./MilestonesTable";
import { Link } from 'react-router-dom';
import EquipmentForm from "./forms/EquipmentForm";

import Auth from '../utils/Auth';

export default class EquipmentEditor extends React.Component {

  constructor(props) {
    super(props);

    const objectId = this.props.match.params.id;

    this.state = {
      objectId: objectId,
      object: null,
      milestones: [],
      isNew: !objectId,
      isLoading: false,
      isSaving: false,
      companies: [],
      departments: [],
      projects: [],
      suppliers: [],
      states: [],
      users: [],
      equipmentStates: [],
    };

    this.onSaveElement = this.onSaveElement.bind(this);
    this.onDeleteElement = this.onDeleteElement.bind(this);
    this.onMilestonesUpdate = this.onMilestonesUpdate.bind(this);
  }

  componentDidMount() {

    const companyId = Auth.getCompany();
    const departmentIds = Auth.getDepartments();
    // const projectIds = Auth.getProjects();

    Suppliers.get().then(result => {
      this.setState({ suppliers: result });
    }).catch(error => {

    });

    InventoryStates.get().then(result => {
      console.log("Equipment states: " + JSON.stringify(result));
      this.setState({ equipmentStates: result });
    });

    Companies.get(companyId).then(result => {
      const companies = this.state.companies;
      companies.push(result);
      this.setState({ companies: companies });
    });

    Departments.getByIds(departmentIds).then(result => {
      this.setState({ departments: result });
    });

    Projects.getByDepartment(departmentIds).then(result => {
      this.setState({ projects: result });
    });

    Users.getByCompany(companyId).then(result => {
      this.setState({ users: result });
    });

    if (this.state.isNew)
      return;


    Equipment.get(this.state.objectId).then(result => {
      this.setState({ object: result });
    }).catch(error => {

    });

    Milestones.getByParentId(this.state.objectId).then(result => {
      this.setState({ milestones: result });
    });

  }

  onSaveElement(objectId, value) {
    this.setState({ isSaving: true });
    value.documents = [];
    value.equipment = [];

    if (!value.milestones) value.milestones = [];
    if (!value.projectId) value.projectId = "";
    if (!value.description) value.description = "";
    if (!value.comment) value.comment = "";

    if (value.acquired)
      value.acquired = value.acquired.unix();

    if (!objectId) {
      Equipment.create(value).then(result => {
        notification.success({ message: "Opprettet nytt utstyr." });
        this.setState({ objectId: result._id, object: result, isNew: false, isSaving: false });
      }).catch(error => {
        notification.error({ message: "Det oppsto en feil under oppretting av utstyret." });
        this.setState({ isSaving: false });
      });
    } else {
      Equipment.update(objectId, value).then(result => {
        notification.success({ message: "Oppdateringen av utstyret var vellykket." });
        this.setState({ objectId: result._id, object: result, isNew: false, isSaving: false });
      }).catch(error => {
        notification.error({ message: "Det oppsto en feil under oppdateringen av utstyret." });
        this.setState({ isSaving: false });
      });
    }

  }


  onDeleteElement() {
    if (!this.state.objectId)
      return;

    Equipment.remove(this.state.objectId).then(result => {
      notification.info({ message: "Utstyret ble slettet fra databasen." });
      this.props.history.push('/equipment');
    }).catch(error => {
      notification.error({ message: "Det oppsto en feil under sletting av utstyret." });
    });
  }


  onMilestonesUpdate() {
    Milestones.getByParentId(this.state.objectId).then(result => {
      this.setState({ milestones: result });
    });
  }


  render() {

    const cardStyle = { marginTop: "20px" };
    const buttonStyle = { marginRight: "20px" };

    const props = { onFormSubmit: this.onSaveElement, ...this.state };

    const WrappedEquipmentForm = Form.create()(EquipmentForm);

    return (
      <div>
        <Card>
          <Link to={"/equipment"} style={buttonStyle}>
            <Button type="dashed" icon="arrow-left">Tilbake</Button>
          </Link>
          <Popconfirm title="Er du sikker på at du vil slette utstyret?" cancelText="Avbryt" onConfirm={this.onDeleteElement} icon={<Icon type="delete" theme="outlined" style={{ color: 'red' }} />}>
            <Button type="danger" icon="delete" disabled={!this.state.objectId}>Slett utstyr</Button>
          </Popconfirm>
        </Card>

        <Row gutter={20}>
          <Col span={12}>
            <Card style={cardStyle} title="Utstyr">
              <WrappedEquipmentForm {...props} />
            </Card>
          </Col>
          <Col span={12}>
            <Card style={cardStyle} title="Oppgaver">
              <MilestonesTable parentObject={this.state.object} milestones={this.state.milestones} isDisabled={this.state.isNew} onUpdate={this.onMilestonesUpdate} />
            </Card>
          </Col>
        </Row>

      </div>
    );
  }
}