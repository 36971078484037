import React from 'react';

import { Menu, Icon } from 'antd';
import { Link } from "react-router-dom";

export default class Navbar extends React.Component {

  constructor(props){
    super(props);
    this.state = { current: "overview" };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({ current: e.key });
  }

  render() {
    return(
      <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">

        <Menu.Item key="dashboard">
          <Link to="/">
            <Icon type="dashboard" />Oversikt
          </Link>
        </Menu.Item>

        <Menu.Item key="vehicles">
          <Link to="/vehicles">
            <Icon type="car" />Dine kjøretøy
          </Link>
        </Menu.Item>

        <Menu.Item key="equipment">
          <Link to="/equipment">
            <Icon type="tool" />Ditt utstyr
          </Link>
        </Menu.Item>

        <Menu.Item key="vehiclesDatabase">
          <Link to="/vehiclesDatabase">
            <Icon type="database" />Alle kjøretøy
          </Link>
        </Menu.Item>

        <Menu.Item key="equipmentDatabase">
          <Link to="/equipmentDatabase">
            <Icon type="database" />Alt utstyr
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}