import {Redirect, Route} from 'react-router-dom';
import React from 'react';
import Auth from '../utils/Auth';
import Login from "../components/login/Login";

export const AuthenticatedRoute = ({ ...props }) => {

  const isAuthenticated = Auth.isAuthenticated();
  if(!isAuthenticated) return (<Login />);

  return <Route {...props} />;
};


export const AuthorizedRoute = ({ permission, ...props }) => {

  const hasRequiredPermission = Auth.hasPermissions(permission);
  if(!hasRequiredPermission) return <Redirect to="/noaccess"/>

  return <Route {...props} />;
};
