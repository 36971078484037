import { client } from './http';

/**
 * Provides access to VehicleTypes
 * The VehicleTypes schema is defined in the API-docs
 */
export default class VehicleTypes {

    /**
     * Retrieves alist of all VehicleTypes
     * @returns {Promise} Promise object that returns a list of elements
     */
    static get() {
        return new Promise((resolve, reject) => {
            const url = '/vehicleTypes/';
            client().get(url).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(null);
            });
        });
    }

}