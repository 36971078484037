
export default class InventoryStates {

    static get() {
        return new Promise((resolve, reject) => {
            resolve([
              { _id: 1, title: "I bruk", description: "" },
              { _id: 2, title: "Ledig (Ikke i bruk)", description: "" },
              { _id: 3, title: "Kassert", description: "" },
              { _id: 4, title: "Til service", description: "" },
              { _id: 5, title: "Under utbedring", description: "" },
              { _id: 6, title: "Defekt", description: "" },
            ]);
        });
    };

}