import { client } from './http';

/**
 * Provides access to departments
 * The Department schema is defined in the API-docs
 */
export default class Departments {



    /**
     * Retrieves a list of all departments
     * @returns {Promise} Promise object that returns a list of elements
     */
    static getAll(companyId) {
        let url = '/departments';

        const params = {};
        if (companyId) params.companyId = companyId;

        return new Promise((resolve, reject) => {
            client().get(url, { params: params }).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.error(err);
                return reject(err);
            });
        });
    };

    static getByIds(departmentIds) {
        return new Promise(async (resolve, reject) => {
            try {
                const results = await this.getAll(null);
                return resolve(results.filter(e => departmentIds.includes(e._id)));
            } catch (error) {
                return reject(error);
            }
        });
    }
}