import React from 'react';
import { Card, Button, Layout } from 'antd';
import Auth from '../../utils/Auth';
import './Login.css';

import PropTypes from 'prop-types';

import Typed from 'react-typed';

export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.onLoginClicked = this.onLoginClicked.bind(this);
  }

  onLoginClicked() {
    const auth = new Auth();
    auth.login();
  }

  render() {
    const appName = process.env.REACT_APP_NAME;
    const appVersion = process.env.REACT_APP_VERSION;

    const logoStyle = {
      margin: "0px 24px 0px 0",
      padding: "0px",
      float: "left"
    };

    const actionsStyle = {
      float: "right",
      lineHeight: "64px"
    };

    const actionButtonStyle = {
      marginRight: "10px"
    };

    const typedStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
    };

    return (
      <div className="login-background" style={{ height: "100%", width: "100%" }}>

        <Layout>
          <Layout.Header style={{ background: "white" }}>
            <div style={logoStyle}>
              <img width={166} height={33} src="/icons/logo.png" alt='logo' />
            </div>

            <div style={actionsStyle}>
              <Button type="dashed" style={actionButtonStyle} href="https://roadworks.no">roadworks.no</Button>
              <Button type="dashed" href="https://dashboard.roadworks.io">Dashbord</Button>
            </div>
          </Layout.Header>
        </Layout>

        <div className="center-div">
          <Card className="login-box">
            <img className="login-box-image" src="/icons/app_icon.png" alt='app icon' style={{ width: "150px", height: "150px", padding: "25px" }} />
            <h2 className="login-box-title">{appName}</h2>
            <div style={typedStyle}>
              <Typed className="login-box-subtitle"
                style={{ float: "right", textAlign: "center", fontSize: "18" }}
                strings={['Versjon ' + appVersion + '. ^3000', 'Pålogging for prosjektledere. ^3000', 'roadmanager@traftec.no ^5000']}
                typeSpeed={40}
                backSpeed={20}
                loop={true}
              />
            </div>

            <br />
            <Button className="login-box-button" onClick={this.onLoginClicked}>Logg inn</Button>
          </Card>
        </div>
      </div>
    )
  }

}

Login.propTypes = {
  bgColor: PropTypes.string
};

Login.defaultProps = {
  bgColor: '#2c3e50'
};
