import React, { Component } from 'react';
import logo from './logo.png';
import './NoAccess.css';

import Auth from '../../utils/Auth';

export default class NoAccess extends Component {

  constructor(props){
    super(props);
    this.onLoginClicked = this.onLoginClicked.bind(this);
  }

  onLoginClicked() {
    const auth = new Auth();
    auth.login();
  }

  render() {
    return (
      <div className="NoAccess">
        <header className="NoAccess-header">
          <img src={logo} className="NoAccess-logo" alt="logo" />
          <p>
            Du har ikke tilgang til EquipmentManager.
          </p>
          <p className="NoAccess-link">Support: roadmanager@traftec.no</p>
          <a className="NoAccess-link" target="_blank" rel="noopener noreferrer" onClick={this.onLoginClicked}>Logg inn</a>
        </header>
      </div>
    );
  }
}
